import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import UnsubscribeEditor from '@/components/UnsubscribeEditor/UnsubscribeEditor.vue'

export default {
  name: 'Unsubscribe',
  components: {
    HeaderTopDashboard,
    UnsubscribeEditor,
  },
}
